import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function MainNavbar() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="bg-body-tertiary"
      style={{
        position: "fixed",
        zIndex: 9999,
        width: "100%",
        paddingLeft: 20,
      }}
    >
      <Container fluid>
        <Row className="w-100">
          <Col xs={6} md={4}>
            <Navbar.Brand as={Link} to="/">
              <img
                src="/assets/images/TBAFinal.jpg"
                alt="tba logo"
                className="img-fluid"
                style={{ maxWidth: "350px", width: "100%" }}
              />
            </Navbar.Brand>
          </Col>
          <Col xs={6} md={8} className="d-flex justify-content-end">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          </Col>
        </Row>
        <Row className="w-100">
          <Col>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <NavDropdown
                  title={t("welcome_message")}
                  id="collapsible-nav-dropdown"
                >
                  <NavDropdown.Item href="/about/trustbroker-africa">
                    {t("about_us")}
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/about/steering-committee">
                    {t("steering")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://baobab.wacren.net/communities/tba/records?q=&l=list&p=1&s=10&sort=newest"
                    target="_blank"
                  >
                    {t("resources")}
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  title={t("Collaborations")}
                  id="collapsible-nav-dropdown"
                >
                  <NavDropdown.Item href="/collaborations/tba-ocf">
                    {t("TBA - OCF")}
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/collaborations/tba-shadowserver">
                    {t("TBA - Shadowserver")}
                  </NavDropdown.Item>
                </NavDropdown>
                <a
                  className="nav-link"
                  href="/registry/index.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("registry")}
                </a>
                <Nav.Link href="/join">{t("join")}</Nav.Link>
                <Nav.Link href="/community/media">{t("community")}</Nav.Link>
                <Nav.Link href="/support">{t("support")}</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Col>
        </Row>
        <Row className="w-100">
          <Col className="d-flex justify-content-end">
            <div>
              <button onClick={() => changeLanguage("en")}>English</button>
              <button onClick={() => changeLanguage("fr")}>Français</button>
            </div>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
}

export default MainNavbar;
