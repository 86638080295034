import React from "react";
import "./Join.css";
import { useTranslation } from "react-i18next";

function AssociatedExpert() {
  const { t } = useTranslation();

  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  // };

  return (
    <div className="associated-expert-page">
      <div className="content">
        <h2 className="title">
          {t("Joining TrustBroker Africa as TBA Associated expert")}
        </h2>
        <p>
          {t(
            "This status will only apply in the future as currently there are no TBA Accredited teams"
          )}
          !
        </p>
        <p>{t("TBA Associates are individuals")}.</p>
        <p>{t("The TBA Associates Policy")}.</p>
        <p>{t("jointly nominate a candidate")}.</p>
      </div>
      <div className="image-container">
        <img
          src="/assets/images/tba_associating.png"
          alt="Placeholder"
          className="placeholder-image"
        />
      </div>
    </div>
  );
}

export default AssociatedExpert;
