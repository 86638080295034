// import React from 'react'
import React from "react";
// import { Routes, Route, Link } from 'react-router-dom';
// import Button from 'react-bootstrap/Button';
import "./About.css";
import { useTranslation } from "react-i18next";

function TrustBrokerAfrica() {
  const { t } = useTranslation();

  // const [showMore, setShowMore] = useState(false);

  // const handleReadMoreClick = () => {
  //   setShowMore(!showMore);

  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  // };

  // };

  return (
    <>
      <div className="about-container">
        {/* <div className="image-section">
      <img src={require('./images/libsense.png').default} alt="Descriptive alt text" />
    </div> */}
        <h2 className="headline">{t("tba")}</h2>
        <p className="descriptions">{t("tba descriptions")}</p>
        <p>{t("tba describtions")}</p>
        <p> {t("tba-descriptions")}</p>
      </div>
      {/* {showMore && (
              <div className="additional-content">
                
        </div>
      )}
    <Button className="read-more" onClick={handleReadMoreClick}>
        {showMore ? "Read less" : "Read more"}
      </Button> */}
    </>
  );
}

export default TrustBrokerAfrica;
