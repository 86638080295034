import React from "react";
// import { Routes, Route, Link } from 'react-router-dom';
// import Button from 'react-bootstrap/Button';
import "./App.css";

function Year2023() {
  return (
    <div className="recent-updates-container">
      <div className="content">
        <div className="update-section">
          <div className="update-section">
            <h3 className="update-title">December 2023</h3>
          </div>
        </div>
        <div className="update-item">
          <span className="update-date">15 Dec 2023</span>
          <span className="update-description">
            bjCSIRT (BJ) is now a listed team
          </span>
        </div>
        <div className="update-section">
          <h3 className="update-title">November 2023</h3>
          <div className="update-item">
            <span className="update-date">28 Nov 2023</span>
            <span className="update-description">
              MoRENet CSIRT (MZ) is now a listed team
            </span>
          </div>
          <div className="update-item">
            <span className="update-date">28 Nov 2023</span>
            <span className="update-description">
              {" "}
              SA NREN CSIRT (ZA) is now a listed team
            </span>
          </div>
          <div className="update-item">
            <span className="update-date">28 Nov 2023</span>
            <span className="update-description">
              National KE-CIRT/CC (KE) is now a listed team
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Year2023;
