import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

// import Layout from "./Components/Layout";
// import Container from "react-bootstrap/Container";
import Home from "./Home";
// import About from "./About/About";
import Join from "./Join/Join";
import Media from "./Community/Media";
// import Blog from "./Community/Blog";
// import GIZProgramme from "./Community/GIZ-Programme";
import Support from "./Support/Support";
import SteeringCommittee from "./About/SteeringCommittee";
import TrustBrokerAfrica from "./About/TBA";
import Resources from "./About/Resources";
// import TeamsDirectory from "./Registry/TeamsDirectory";
// import UpdateHistory from "./Registry/UpdateHistory";
// import RecentUpdates from "./Registry/RecentUpdates";
import Upcomingevents from "./Upcomingevents";
// import Registry from "./Registry/Registry";
import ListedTeam from "./Join/LIstedTeam";
import AccreditedTeam from "./Join/AccreditedTeam";
import AssociatedTeam from "./Join/AssociatedTeam";
import Footer from "./Components/Footer/Footer";
import MainNavbar from "./Components/Navbar/navbar";
import CSIRList from "./CSIRList";
import TeamList from "./TeamList";
import TeamInfo from "./TeamInfo";
import TeamDetails from "./TeamDetails";
import GmCSIRT from "./GmCSIRT";
import KENETCert from "./KENETCert";
import KECirt from "./KECirt";
import MoreNetCsirt from "./MoreNetCsirt";
import SACsirt from "./SACsirt";
import UCTCsirt from "./UCTCsirt";
import TGCert from "./TGCert";
import RenuCert from "./RenuCert";
import Year2024 from "./Year2024";
import Year2023 from "./Year 2023";
import CertifiedTeamsList from "./CertifiedTeamsList";
import CertifiedList from "./CertifiedList";
import AccreditedTeamsList from "./AccreditedTeamsList";
import AccreditedList from "./AccreditedList";
import ReadMore from "./ReadMore";
import TbaTemplateForm from "./TbaTemplateForm";
import TBAOCF from "./collaborations/TBA-OCF";
import TBAShadowServer from "./collaborations/TBA-Shadowserver";

function App() {
  return (
    <div className="main-container">
      <Router>
        <MainNavbar />
        <div className="main-content">
          <div className="routes-container">
            <Routes>
              {/* <Route path="/" element={<Layout />}> */}
              <Route path="/" element={<Home />} />
              <Route path="/read-more" element={ReadMore} />
              <Route
                path="/about/trustbroker-africa"
                element={<TrustBrokerAfrica />}
              />
              <Route
                path="/about/steering-committee"
                element={<SteeringCommittee />}
              />
              <Route path="/about/resources" element={<Resources />} />
              <Route path="/community/media" element={<Media />} />
              {/* <Route path="/community/blog" element={<Blog />} />
          <Route path="/community/giz-programme" element={<GIZProgramme />} /> */}
              {/* <Route path="/registry/" element={<Registry />} />
          <Route path="/registry/teams-directory" element={<TeamsDirectory />} /> */}
              <Route path="/upcomingevents" element={<Upcomingevents />} />
              {/* <Route path="/registry/recent-updates" element={<RecentUpdates />} />
          <Route path="/registry/update-history" element={<UpdateHistory />} /> */}
              <Route path="/csirlist" element={<CSIRList />} />
              <Route path="/teamlist" element={<TeamList />} />
              <Route path="/TeamDetails" element={<TeamDetails />} />
              <Route
                path="/accreditedteamslist"
                element={<AccreditedTeamsList />}
              />
              <Route path="/accreditedlist" element={<AccreditedList />} />
              <Route
                path="/certifiedteamslist"
                element={<CertifiedTeamsList />}
              />
              <Route path="/collaborations/tba-ocf" element={<TBAOCF />} />
              <Route
                path="/collaborations/tba-shadowserver"
                element={<TBAShadowServer />}
              />
              <Route path="/certifiedlist" element={<CertifiedList />} />
              <Route path="/join" element={<Join />} />
              <Route path="/teaminfo" element={<TeamInfo />} />
              <Route path="/teamdetails" element={<TeamDetails />} />
              <Route path="/gmcsirt" element={<GmCSIRT />} />
              <Route path="kenetcert" element={<KENETCert />} />
              <Route path="kecirt" element={<KECirt />} />
              <Route path="sacsirt" element={<SACsirt />} />
              <Route path="uctcsirt" element={<UCTCsirt />} />
              <Route path="tgcert" element={<TGCert />} />
              <Route path="renucert" element={<RenuCert />} />
              <Route path="year2024" element={<Year2024 />} />
              <Route path="year2023" element={<Year2023 />} />
              <Route path="morenetcsirt" element={<MoreNetCsirt />} />
              <Route path="/join/listed-team" element={<ListedTeam />} />
              <Route
                path="/join/accredited-team"
                element={<AccreditedTeam />}
              />
              <Route
                path="/join/associated-team"
                element={<AssociatedTeam />}
              />

              <Route path="/support" element={<Support />} />
              <Route path="/tbatemplateform" element={<TbaTemplateForm />} />
              <Route path="*" element={<h2>404 Not Found</h2>} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </div>
  );
}

export default App;
