import React from "react";
import "./App.css";
import { useTranslation } from "react-i18next";

const TbaTemplateForm = () => {
  const { t } = useTranslation();

  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  // };

  return (
    <div className="tba-template-form">
      <h2>{t("TBA Template")}: list_v32.txt</h2>
      <p>
        <strong>{t("Version 3.2 of 1 March 2021")}</strong>
      </p>

      <p>
        {t("Send as Electronic Mail to the TBA as")}{" "}
        <strong>*an ASCII file!*</strong>
      </p>
      <p>{t("PDF, WORD or OPENOFFICE documents will not be processed")}.</p>
      <p>
        {t("public key files for ")}
        <strong>*all*</strong> {t("keys referenced")}. .
      </p>
      <p>
        {t("You will find our contact data and public PGP/GPG key on")}:
        <a
          href="https://trustbroker-dev.ren.africa/support"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://trustbroker-dev.ren.africa/support
        </a>
      </p>
      <p>{t("Please note")} .</p>
      <p>
        {t("All team-related information will be available from")}:
        <a
          href="https://trustbroker-dev.ren.africa/registry/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://trustbroker-dev.ren.africa/registry/
        </a>
      </p>

      <hr />

      <h3>{t("Submitter Information")}:</h3>
      <p>{t("submit a listing request")}!</p>

      <form className="submitter-form">
        <div className="form-group">
          <label>{t("First and last name")}:</label>
          <input type="text" name="name" />
        </div>

        <div className="form-group">
          <label>{t("Email address")}:</label>
          <input type="email" name="email" />
        </div>

        <div className="form-group">
          <label>{t("PGP fingerprint")}:</label>
          <input type="text" name="pgpFingerprint" />
        </div>

        <div className="form-group">
          <label>{t("Phone number including country code")}:</label>
          <input type="tel" name="phoneNumber" />
        </div>

        <div className="form-group">
          <label>
            {t(
              "Describe your role in which capacity you submitted this request"
            )}
            :
          </label>
          <textarea name="role" rows="3" />
        </div>

        <p>{t("Please provide the information as complete as possible")}.</p>

        <hr />

        <h3>{t("Section 1) Team Name")}:</h3>

        <div className="form-group">
          <label>{t("Acronym used")}:</label>
          <input type="text" name="acronym" placeholder="" />
        </div>

        <div className="form-group">
          <label>{t("Official team name")}:</label>
          <input type="text" name="teamName" placeholder="" />
        </div>

        <div className="form-group">
          <label>{t("Host organisation")}:</label>
          <input type="text" name="hostOrg" placeholder=" " />
        </div>

        <div className="form-group">
          <label>{t("Main Country")}:</label>
          <input type="text" name="mainCountry" placeholder="" />
        </div>

        <div className="form-group">
          <label>{t("Additional countries")}:</label>
          <input type="text" name="additionalCountries" placeholder="" />
        </div>

        <div className="form-group">
          <label>{t("Team Establishment Date")}:</label>
          <input type="date" name="establishmentDate" />
        </div>

        <hr />

        <h3>{t("Section 2) Type of Team")}:</h3>

        <div className="form-group">
          <label>{t("Type")}:</label>
          <div className="checkbox-group">
            <label>
              <input type="checkbox" name="type" value="CIIP" /> CIIP
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                name="type"
                value="Commercial Organisation"
              />{" "}
              {t("Commercial Organisation")}
            </label>
            <br />
            <label>
              <input type="checkbox" name="type" value="Financial Sector" />{" "}
              {t("Financial Sector")}
            </label>
            <br />
            <label>
              <input type="checkbox" name="type" value="Government" />{" "}
              {t("Government")}
            </label>
            <br />
            <label>
              <input type="checkbox" name="type" value="International Body" />{" "}
              {t("International Body")}
            </label>
            <br />
            <label>
              <input type="checkbox" name="type" value="ISP Customer Base" />{" "}
              {t("ISP Customer Base")}{" "}
            </label>
            <br />
            <label>
              <input type="checkbox" name="type" value="Law Enforcement" />{" "}
              {t("Law Enforcement")}
            </label>
            <br />
            <label>
              <input type="checkbox" name="type" value="Military" />{" "}
              {t("Military")}
            </label>
            <br />
            <label>
              <input type="checkbox" name="type" value="National" />{" "}
              {t("National")}{" "}
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                name="type"
                value="Non-Commercial Organisation"
              />{" "}
              {t("Non-Commercial Organisation")}
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                name="type"
                value="Research & Education Network"
              />{" "}
              {t("Research & Education Network")}
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                name="type"
                value="Service Provider Customer Base"
              />{" "}
              {t("Service Provider Customer Base")}
            </label>
            <br />
            <label>
              <input type="checkbox" name="type" value="Vendor Customer Base" />{" "}
              {t("Vendor Customer Base")}
            </label>
          </div>
        </div>

        <div className="form-group">
          <label>{t("Other type of team")}:</label>
          <input type="text" name="otherType" placeholder="" />
        </div>

        <p>
          {t(
            "Some explanation to the above list that should allow a better choice on your side"
          )}
          :
          <ul>
            <li>{t("ISP Customer Base covers only commercial sector")}</li>
            <li>{t("Government teams include law enforcement")}</li>
            <li>
              {t(
                "Vendor teams are responsible for the security of their products, not their internal security teams"
              )}
            </li>
            <li>
              {t(
                "Service Customer Based teams are those that offer incident response and strongly to incident response-related services"
              )}
              .
            </li>
            <li>{t("outsourced")}.</li>
          </ul>
        </p>

        <hr />

        <h3>{t("Section 3) Description of the constituency")}:</h3>

        <div className="form-group">
          <label>({t("Internet Domains")}:</label>
          <textarea name="constituency" rows="5" placeholder="" />
        </div>

        <hr />

        <h3>{t("Section 4) Contact Information")}:</h3>

        <div className="form-group">
          <label>
            {t("Postal Mailing Address of the team including country")}:
          </label>
          <textarea name="postalAddress" rows="3" placeholder="" />
        </div>
        <div className="form-group">
          <label>{t("Team Email address")}:</label>
          <input type="email" name="email" />
        </div>
        <div className="form-group">
          <label>{t("Phone number including country code")}:</label>
          <input type="tel" name="primaryPhone" placeholder="+" />
        </div>
        <div className="form-group">
          <label>{t("Emergency phone number including country code")}:</label>
          <input type="tel" name="primaryPhone" placeholder="+" />
        </div>
        <div className="form-group">
          <label>{t("PGP fingerprint")}:</label>
          <input type="text" name="primaryPgp" placeholder="0x" />
        </div>
        <div className="form-group">
          <label>{t("World Wide Web server")}:</label>
          <input type="text" name="webServer" placeholder=" " />
        </div>

        <div className="form-group">
          <label>{"Business hours"}:</label>
          <input type="text" name="businessHours" placeholder=" " />
        </div>

        <div className="form-group">
          <label>{t("Time-zone")}:</label>
          <input type="text" name="timeZone" placeholder=" " />
        </div>
        <hr />
        <div className="team-representatives-form">
          <h3>{t("Section 5) Team Representatives")}</h3>
          <p>{t("speak authoritatively for the team")}!</p>

          <div className="form-section">
            <h3>* {t("Primary Team Representative")}</h3>
            <div className="form-group">
              <label>{t("First and last name")}:</label>
              <input type="text" name="primaryName" placeholder="" />
            </div>

            <div className="form-group">
              <label>{t("Email address")}:</label>
              <input type="email" name="primaryEmail" placeholder="" />
            </div>

            <div className="form-group">
              <label>{t("PGP fingerprint")}:</label>
              <input type="text" name="primaryPgp" placeholder="0x" />
            </div>

            <div className="form-group">
              <label>{t("Phone number including country code")}:</label>
              <input type="tel" name="primaryPhone" placeholder="+" />
            </div>
          </div>

          <div className="form-section">
            <h3>* {t("Secondary Team Representative")}</h3>
            <div className="form-group">
              <label>{t("First and last name")}:</label>
              <input type="text" name="secondaryName" placeholder="" />
            </div>

            <div className="form-group">
              <label>{t("Email address")}:</label>
              <input type="email" name="secondaryEmail" placeholder="" />
            </div>

            <div className="form-group">
              <label>{t("PGP fingerprint")}:</label>
              <input type="text" name="secondaryPgp" placeholder="0x" />
            </div>

            <div className="form-group">
              <label>{t("Phone number including country code")}:</label>
              <input type="tel" name="secondaryPhone" placeholder="+" />
            </div>
          </div>

          <hr />

          <h3>{t("Section 6) Memberships")}</h3>

          <div className="form-group">
            <p>{t("team is a member of FIRST")}:</p>
            <label>{t("Full member since")}:</label>
            <input type="text" name="firstMembership" placeholder="" />
          </div>

          <div className="form-group">
            <label>{t("If the team is a member")}:</label>
            <div className="form-group">
              <label>{t("Listed since")}:</label>
              <input type="text" name="tfCsirtListedSince" placeholder=" " />
            </div>
            <div className="form-group">
              <label>{t("Accredited since")}:</label>
              <input
                type="text"
                name="tfCsirtAccreditedSince"
                placeholder=" "
              />
            </div>
          </div>

          <div className="form-group">
            <label>{t("related forum or organisation")} :</label>
            <div className="form-group">
              <label>{t("Name")}:</label>
              <input type="text" name="certForumName" placeholder=" " />
            </div>
            <div className="form-group">
              <label>{t("URL public information")}:</label>
              <input type="text" name="certForumURL" placeholder=" " />
            </div>
          </div>

          <hr />

          <h3>{t("Section 7) Cooperation and Collaboration")}</h3>
          <p>
            {t("basic information ")}. (
            {t("web page and point of contact is needed")}!)
          </p>

          <div className="form-group">
            <textarea
              name="cooperationAndCollaboration"
              rows="5"
              placeholder=" "
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default TbaTemplateForm;
