import React from "react";
// import { Routes, Route, Link } from 'react-router-dom';
// import Button from 'react-bootstrap/Button';
import "./App.css";

const Year2024 = () => {
  return (
    <div className="recent-updates-container">
      <div className="content">
        <div className="update-section"></div>

        <div className="update-section">
          <h3 className="update-title">April 2024</h3>
          <div className="update-item">
            <span className="update-date">08 Apr 2024</span>
            <span className="update-description">
              {" "}
              KENET-CERT (KE) is now a listed team
            </span>
          </div>
          <h3 className="update-title">January 2024</h3>
          <div className="update-item">
            <span className="update-date">16 Jan 2024</span>
            <span className="update-description">
              RENU-CERT (UG) is now a listed team
            </span>
          </div>
          <div className="update-item">
            <span className="update-date">18 Jan 2024</span>
            <span className="update-description">
              National KE-CIRT/CC (KE) is now a listed team
            </span>
          </div>
          <div className="update-item">
            <span className="update-date">23 Jan 2024</span>
            <span className="update-description">
              Botswana-CSIRT (BW) is now a listed team
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Year2024;
