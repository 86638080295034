import React from "react";
import { useTranslation } from "react-i18next";
// import "./TBA-OCF.css";

function TBAShadowServer() {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto">
      <h1 className="text-center mb-4">{t("TBA - Shadowserver")}</h1>
      <p>{t("TBA_Shadowserver_Content1")}</p>
      <p>{t("TBA_Shadowserver_Content2")} </p>
      <p>{t("TBA_Shadowserver_Content3")}</p>
      <p>{t("TBA_Shadowserver_Content4")}</p>

      <div className="text-center mt-4">
        <img
          src="/assets/images/shadowserver.png"
          alt="OCF"
          className="img-fluid"
          style={{ maxWidth: "30%", height: "auto" }}
        />
      </div>
    </div>
  );
}

export default TBAShadowServer;
