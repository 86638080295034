import React from "react";
import "./About.css";
import { useTranslation } from "react-i18next";

function Resources() {
  const { t } = useTranslation();

  return (
    <div className="resource-container">
      <h1>{t("resource")}</h1>

      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Type</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <a
                href="/CSIRT_setting_up_guide.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("CSIRT")}.
              </a>
            </td>
            <td>{t("presentation")}</td>
            <td>2024</td>
          </tr>
          <tr>
            <td>
              <a
                href="/ngCERT role in Cybersecurity EDITED.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("NGCERT")}.
              </a>
            </td>
            <td>{t("presentation")}</td>
            <td>2023</td>
          </tr>
          <tr>
            <td>
              <a
                href="/TRANSITI-OrganisationalModule_Mirek(2).pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("TRANSIT")}
              </a>
            </td>
            <td>{t("presentation")}</td>
            <td>2023</td>
          </tr>
          <tr>
            <td>
              <a
                href="/TRANSITS I - Operational Module.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("TRANSITS")}
              </a>
            </td>
            <td>{t("presentation")}</td>
            <td>2023</td>
          </tr>
          <tr></tr>
        </tbody>
      </table>
    </div>
  );
}

export default Resources;
