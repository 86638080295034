import React, { useState } from "react";
import "./Join.css"; // Ensure you import the CSS file
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Join() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);

  const handleReadMoreClick = () => {
    setShowMore(!showMore);
  };

  const navigation = (url) => {
    navigate(url);
  };

  const cardData = [
    {
      title: "TBA Listed team",
      imgSrc: "assets/images/listed.jpeg",
      alt: "TBA Listed team",
      link: "/join/listed-team",
    },
    {
      title: "TBA Accredited team",
      imgSrc: "assets/images/accredited.jpeg",
      alt: "TBA Accredited team",
      link: "/join/accredited-team",
    },
    {
      title: "TBA Associated expert",
      imgSrc: "assets/images/associated.jpeg",
      alt: "TBA Associated expert",
      link: "/join/associated-team",
    },
  ];

  return (
    <div className="join-page">
      <h1>{t("join_tba")}</h1>
      <p>
        {t("security and Incident Response teams")}{" "}
        <a
          href="https://www.trusted-introducer.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("TI Service")}
        </a>{" "}
        {t("compatible")}.
      </p>
      <p>{t("safeguard")}.</p>

      {showMore && (
        <div className="additional-content">
          <p>{t("categories")}:</p>
          <ul className="sub-list">
            <li>
              {t("listed")}, {t("provides basic information")}
            </li>
            <li>
              {t("accredited")}, {t("best practices")};
            </li>
            <li>
              {t("security experts")} {t("participate")}.
            </li>
          </ul>
          <div className="additional-contents">
            <p>{t("TBA Accreditation")}.</p>
            <p>
              {t("first team")}{" "}
              <a
                href="https://www.trusted-introducer.org/processes/standards.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("are explained here")}
              </a>
              .
            </p>
          </div>
        </div>
      )}
      <Button
        className="dflex items-center justify-center text-center mb-4 btn"
        onClick={handleReadMoreClick}
      >
        {showMore ? t("Show Less") : t("Read More")}
      </Button>

      <div className="card-container">
        {cardData.map((card, index) => (
          <div className="card-main" key={index}>
            <div className="card__content">
              <h2 className="card__title">{t(card.title)}</h2>
              <div className="card__img">
                <img src={card.imgSrc} alt={card.alt} />
              </div>
            </div>
            <Button onClick={() => navigation(card.link)} className="button">
              {t("joins")}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Join;
