import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FaDatabase, FaSync, FaHistory } from "react-icons/fa";
import "./App.css";
import { useTranslation } from "react-i18next";

// ImageSlider Component
const ImageSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <Slider {...settings}>
        <div>
          <img
            src="/assets/images/sim3slider2.jpeg"
            alt="Slide 1"
            style={imageStyle}
          />
        </div>
        <div>
          <img
            src="/assets/images/sim3slider.jpeg"
            alt="Slide 2"
            style={imageStyle}
          />
        </div>
        <div>
          <img
            src="/assets/images/image2.png"
            alt="Slide 3"
            style={imageStyle}
          />
        </div>
      </Slider>
    </div>
  );
};

// Styles for the images in the slider
const imageStyle = {
  width: "100%",
  height: "auto",
  borderRadius: "8px",
};

// Home Component
function Home() {
  const { t } = useTranslation();

  return (
    <div className="home">
      <ImageSlider />

      <div>
        <h2 className="intro-text">{t("welcome")} </h2>
        <div className="text-intro">
          <h5>{t("services")} </h5>

          <div className="text-content">
            <p>{t("security")}</p>
            <p>{t("securities")}</p>
            <p>
              {t("securitys")}{" "}
              <Link to="/join" className="link">
                {t("joins")}!
              </Link>
            </p>
            <p>{t("listed")}.</p>
            <p>
              {t("interested")}{" "}
              <Link to="/support" className="link">
                {t("interest")}
              </Link>
            </p>
          </div>
        </div>
      </div>

      <div className="join-section">
        <h2 className="join-text">{t("joining TBA")}</h2>
        <p>{t("more")}</p>
        <Link to="/join">
          <Button variant="primary">{t("join us")}</Button>
        </Link>
      </div>

      <div className="registry-container">
        <h2 className="registry-title">{t("registry")}</h2>
        <div className="registry-icons">
          <div className="registry-item">
            <Link to="/registry/teams-directory">
              <FaDatabase className="icon" />
              <p className="icon-label">{t("teams directory")}</p>
            </Link>
          </div>
          {/* <div className="registry-item">
            <Link to="/registry">
              <FaFolderOpen className="icon" />
              <p className="icon-label">Team Database</p>
            </Link>
          </div> */}
          <div className="registry-item">
            <Link to="/registry/recent-updates">
              <FaSync className="icon" />
              <p className="icon-label">{t("recent updates")} </p>
            </Link>
          </div>
          <div className="registry-item">
            <Link to="/registry/update-history">
              <FaHistory className="icon" />
              <p className="icon-label">{t("update history")}</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
